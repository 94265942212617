import constate from "constate";
import { useState } from "react";
import { IPartner } from "~/services/api/partner";

const [PartnerProvider, usePartner] = constate(() => {
  const [partners, setPartners] = useState<IPartner[]>([]);

  return { partners, setPartners };
});

export { PartnerProvider };
export default usePartner;
