import ApiService from "../api";

export type IPartner = {
  uuid: string;
  name: string;
  document_number: string;
  document_type: string;
  email: string;
  phone: string;
  birth_date: string;
  nationality: string;
  marital_status: string;
  occupation: string;
};

export type IResponsePartner = {
  success: boolean;
  payload: IPartner[];
  error: null;
};

export const getPartnersAPI = () => {
  return ApiService.HttpGet<IResponsePartner>({
    route: "register/partner/",
    token: true,
  });
};
