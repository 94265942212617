import { createBrowserRouter } from "react-router-dom";
//import PrivateRouter from "~/components/PrivateRouter";
import PublicRouter from "~/components/PublicRouter";
import DashboardStructure from "~/pages/app/DashboardStructure";

import Operations from "~/pages/app/Operations/Operations";
import Simulation from "~/pages/app/Operations/Simulation";

import Login from "~/pages/auth/Login";
import Cessions from "~/pages/app/Cessions/Cessions";
import Excluded from "~/pages/app/Excluded/Excluded";
import NewOperations from "~/pages/app/Operations/NewOperation/NewOperation";
import PrivateRouter from "~/components/PrivateRouter";

const defaultOptions = {
  errorElement: <></>,
};

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRouter>
        <DashboardStructure />
      </PrivateRouter>
    ),
    ...defaultOptions,
  },
  {
    path: "/login",
    element: (
      <PublicRouter>
        <Login />
      </PublicRouter>
    ),
    ...defaultOptions,
  },
  {
    path: "/app",

    element: (
      <PrivateRouter>
        <DashboardStructure />
      </PrivateRouter>
    ),
    ...defaultOptions,
    children: [
      {
        path: "panel",
        element: (
          <PrivateRouter>
            <Operations />
          </PrivateRouter>
        ),
      },

      {
        path: "credit/assignments",
        element: (
          <PrivateRouter>
            <Cessions />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/excludeds",
        element: (
          <PrivateRouter>
            <Excluded />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/operations",
        element: (
          <PrivateRouter>
            <Operations />
          </PrivateRouter>
        ),
      },

      {
        path: "credit/operations/new",
        element: (
          <PrivateRouter>
            <NewOperations />
          </PrivateRouter>
        ),
      },
      {
        path: "credit/simulation",
        element: (
          <PrivateRouter>
            <Simulation />
          </PrivateRouter>
        ),
      },
    ],
  },
]);

export default AppRouter;
