import { Fragment, HTMLAttributes } from "react";
import styled from "styled-components";

const Container = styled.div<ModalProps>`
  background-color: ${({ open }) =>
    open ? "rgba(0, 0, 0, 0.5)" : "rgba(0,0,0,0)"};
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  display: flex;
  justify-content: end;
  align-items: center;
  transition: all 0.5s ease;

  pointer-events: ${({ open }) => !open && "none"};
`;

const ModalContainer = styled.div<ModalProps>`
  width: ${({ open }) => (open ? "670px" : "0px")};
  max-width: 100%;
  min-height: ${({ open }) => (open ? "100vh" : "0px")};
  position: fixed;
  right: 0;

  transition: all 0.5s ease;
`;

const AppModal = styled.div<ModalProps>`
  background-color: aliceblue;

  max-width: 100%;
  width: 670px;
  min-height: 100vh;
  padding: ${({ header }) => !header && "72px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 500;
  justify-content: start;
  pointer-events: ${({ open }) => (open ? "all" : "none")};
  opacity: ${({ open }) => (open ? "1" : "0")};
  transform: ${({ open }) => (open ? "translateX(0px)" : "translateX(600px)")};
  transition: all 0.5s ease;
  will-change: pointer-events, opacity;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-weight: 300;

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const ImgClose = styled.img`
  width: 20px;
  filter: brightness(0) saturate(100%) invert(58%) sepia(10%) saturate(22%)
    hue-rotate(356deg) brightness(99%) contrast(87%);
`;

const ButtonClose = styled.button`
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.3s,
    filter 0.3s;

  &:hover {
    ${ImgClose} {
      transition:
        background-color 0.3s,
        filter 0.3s;

      filter: brightness(0) saturate(100%) invert(16%) sepia(8%) saturate(0%)
        hue-rotate(259deg) brightness(93%) contrast(102%);
    }
  }
`;

const ModalHeader = styled.div<ModalProps>`
  width: 100%;
  min-height: 108px;
  border-radius: 7px 7px 0px 0px;
  padding: 36px 32px;
  display: ${({ header }) => (header ? "flex" : "none")};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const ContainerChildren = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  max-height: calc(100vh - 108px);
`;

export type ModalProps = HTMLAttributes<HTMLDivElement> & {
  open?: boolean;
  header?: boolean;
  title?: string;
  children?: JSX.Element;
  toggleModal: () => void;
};

const LateralModal = (props: ModalProps) => {
  const { children, title, toggleModal, open } = props;

  return (
    <Fragment>
      <Container onClick={toggleModal} {...props} data-lateral-status={open}>
        <ModalContainer onClick={(e) => e.stopPropagation()} {...props}>
          <Fragment>
            <AppModal {...props}>
              <>
                <ModalHeader {...props}>
                  <Fragment>
                    <Title>{title}</Title>
                    <ButtonClose onClick={toggleModal}>
                      <ImgClose src={""} />
                    </ButtonClose>
                  </Fragment>
                </ModalHeader>
                <ContainerChildren>{children && children}</ContainerChildren>
              </>
            </AppModal>
          </Fragment>
        </ModalContainer>
      </Container>
    </Fragment>
  );
};

export default LateralModal;
