import constate from "constate";
import { useState } from "react";
import { ICreditProducts } from "~/services/api/creditProduct";
import { IOperations } from "~/services/api/operations";

const [OperationProvider, useOperation] = constate(() => {
  const [operations, setOperations] = useState<IOperations[]>([]);
  const [creditProducts, setCreditProducts] = useState<ICreditProducts[]>([]);

  const [addInvolved, setAddInvolved] = useState<boolean>(false);

  return {
    operations,
    setOperations,
    creditProducts,
    setCreditProducts,
    addInvolved,
    setAddInvolved,
  };
});

export { OperationProvider };
export default useOperation;
