import { Button, Input, InputSelect, Radio } from "prosperita-dumbo-react";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import LateralModal from "~/components/LateralModal";
import useOperation from "~/hooks/useOperation";
import CloseIcon from "~/assets/Close--outline.png";
import SaveIcon from "~/assets/Save.png";
import usePartner from "~/hooks/usePartner";
import { IPartner } from "~/services/api/partner";
import { zIndex } from "~/services/utils";
import { useFormik } from "formik";
import Label from "~/components/Label";
const Container = styled.div`
  width: 100%;
  padding: 30px;
  display: grid;
  height: auto;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  z-index: 2;
`;

const Footer = styled.div`
  width: 90%;
  position: fixed;
  bottom: 0;
  display: grid;
  padding-bottom: 50px;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
`;

type Values = {
  involved_partner_uuid: string;
  related_to_uuid: string;
  relationship: string;
  auth_type: string;
};

const initialValues: Values = {
  involved_partner_uuid: "",
  related_to_uuid: "",
  relationship: "",
  auth_type: "",
};

const NewInvolved = () => {
  const { addInvolved, setAddInvolved } = useOperation();
  const { partners } = usePartner();
  const [isSigner] = useState<boolean>(false);

  const { setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const handleClose = () => {
    setAddInvolved(false);
  };

  const bodyRef = useRef(document.body); // Referência ao body

  /* const handleAddInvolvedInStorage = () => {}; */

  const listRelationship = useMemo(() => {
    return [
      { label: "Emissor", value: "issuer" },
      { label: "Fiador Parceiro", value: "partnerGuarantor" },
      { label: "Fiador", value: "guarantor" },
      { label: "Procurador", value: "proxy" },
      { label: "Fiador Procurador", value: "proxy_guarantor" },
      { label: "Parceiro", value: "partner" },
      { label: "Cônjuge", value: "spouse" },
      { label: "Consentidor", value: "consenter" },
      { label: "Testemunha", value: "witness" },
      { label: "Cedente", value: "assignor" },
      { label: "Cessionário", value: "assignee" },
      { label: "Endossante", value: "endorser" },
      { label: "Empregador", value: "employer" },
      { label: "Credor", value: "creditor" },
      { label: "Devedor", value: "debtor" },
      { label: "Devedor Parceiro", value: "partner_debtor" },
      { label: "Agente de Cobrança", value: "billing_agent" },
      {
        label: "Usuário Aprovador do Agente de Cobrança",
        value: "billing_agent_approver_user",
      },
      { label: "Beneficiário", value: "beneficiary" },
      { label: "Agente Notarial", value: "notary_agent" },
      { label: "Agente de Liquidação", value: "settlement_agent" },
      { label: "Credor Endossante", value: "creditor_endorser" },
      { label: "Fiel Depositário", value: "faithful_custodian" },
      { label: "Fiador Solidário", value: "joint_guarantor" },
      { label: "Fiador de Terceiro", value: "third_party_guarantor" },
      { label: "Representante Legal", value: "legal_representative" },
    ];
  }, []);

  const listAuths = useMemo(() => {
    return [
      { label: "Email", value: "email" },
      { label: "Biometria", value: "biometric" },
      { label: "ICP Brasil", value: "icp_brasil" },
    ];
  }, []);

  const listSends = useMemo(() => {
    return [
      { label: "Email", value: "email" },
      { label: "SMS", value: "sms" },
      { label: "Whatsapp", value: "whatsapp" },
    ];
  }, []);
  const optionsRelationship = useMemo(() => {
    return listRelationship?.sort((a, b) =>
      a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
    );
  }, [listRelationship]);

  const partnersOptions = useMemo(() => {
    return partners
      .map((partner: IPartner) => ({
        value: partner.uuid,
        label: partner.name,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [partners]);

  const authOptions = useMemo(() => {
    return listAuths
      .map((auth) => ({
        value: auth.value,
        label: auth.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [listAuths]);

  const sendsOptions = useMemo(() => {
    return listSends
      .map((auth) => ({
        value: auth.value,
        label: auth.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [listSends]);

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };
  useEffect(() => {
    if (addInvolved) {
      const body = bodyRef.current;
      body.style.overflow = "hidden";
    } else {
      const body = bodyRef.current;
      body.style.overflow = "";
    }
    return () => {
      const body = bodyRef.current;
      body.style.overflow = "";
    };
  }, [addInvolved]);

  console.log(isSigner);

  return (
    <LateralModal
      open={addInvolved}
      title="Envolvido"
      header
      toggleModal={() => handleClose()}
    >
      <Container>
        <div style={{ gridColumn: "span 6", zIndex: zIndex }}>
          <InputSelect
            name="involved_partner_uuid"
            id="involved_partner_uuid"
            kind="default"
            size="large"
            label="Envolvido"
            onChange={(e) => handleSelect("involved_partner_uui", e)}
            options={partnersOptions}
            placeholder="Selecione uma opção"
          />
        </div>

        <div style={{ gridColumn: "span 6", zIndex: zIndex }}>
          <InputSelect
            name="related_to_uuid"
            kind="default"
            size="large"
            label="Relacionado à"
            placeholder="Selecione uma opção"
            onChange={(e) => handleSelect("related_to_uuid", e)}
            options={partnersOptions}
          />
        </div>

        <div style={{ gridColumn: "span 12" }}>
          <InputSelect
            kind="default"
            size="large"
            options={optionsRelationship}
            label="Tipo de relação"
            placeholder="Selecione uma opção"
          />
        </div>

        <div style={{ gridColumn: "span 6" }}>
          <InputSelect
            kind="default"
            size="large"
            label="Forma de envio"
            placeholder="Selecione uma opção"
            options={sendsOptions}
          />
        </div>

        <div style={{ gridColumn: "span 6" }}>
          <InputSelect
            kind="default"
            size="large"
            label="Forma de validação"
            placeholder="Selecione uma opção"
            options={authOptions}
          />
        </div>

        <div
          style={{
            gridColumn: "span 6",
          }}
        >
          <Label text="Assinatura" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",

              gap: "16px",
            }}
          >
            <div
              style={{
                marginTop: "10px",

                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                gap: "16px",
              }}
            >
              {" "}
              <div>
                <Radio label="Signatário" />
              </div>
              <div>
                <Radio label="Não Signatário" />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            gridColumn: "span 6",
          }}
        >
          <Input
            type="text"
            size="large"
            disabled={!isSigner}
            placeholder="%"
            label="Porcentagem da assinatura (%)"
          />
        </div>

        <div style={{ height: "200px" }}></div>
        <Footer>
          {" "}
          <div style={{ gridColumn: "span 6" }}>
            <Button
              kind="ghost"
              size="large"
              onClick={() => handleClose()}
              label="Cancelar"
              icon={CloseIcon}
            />
          </div>
          <div style={{ gridColumn: "span 6" }}>
            <Button
              kind="primary"
              size="large"
              icon={SaveIcon}
              onClick={() => handleClose()}
              label="Adicionar envolvido"
            />
          </div>
        </Footer>

        {/* <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
           
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
          
            placeholder="Selecione uma opção"
          />
        </SelectContainer>
        <SelectContainer>
          <InputSelect
            kind="default"
            size="large"
            label="Produto"
        
            placeholder="Selecione uma opção"
          />
        </SelectContainer> */}
      </Container>
    </LateralModal>
  );
};

export default NewInvolved;
