import constate from "constate";
import { useState } from "react";

const [UserProvider, useUser] = constate(() => {
  const [isFirstLogin, setIsFirstLogin] = useState<boolean>(false);

  return {
    isFirstLogin,
    setIsFirstLogin,
  };
});

export { UserProvider };

export default useUser;
