import "./app.scss";
import pipe from "./utils/pipe";
import withProvider from "./utils/withProvider";
import { UserProvider } from "./hooks/useUser";
import useLoading, { LoadingProvider } from "./hooks/useLoading";
import { AuthProvider } from "./hooks/useAuth";
import { RouterProvider } from "react-router-dom";
import { Loading } from "@carbon/react";
import AppRouter from "./routes/AppRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "prosperita-dumbo-react/dist/style.css";
import { OperationProvider } from "./hooks/useOperation";
import { DebitorProvider } from "./hooks/useDebitor";
import { PartnerProvider } from "./hooks/usePartner";

const App = () => {
  const { loading } = useLoading();

  return (
    <main>
      <RouterProvider router={AppRouter} />
      <Loading withOverlay={true} active={loading} style={{ zIndex: 4 }} />
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </main>
  );
};

const enhance = pipe(
  withProvider(
    UserProvider,
    LoadingProvider,
    AuthProvider,
    OperationProvider,
    DebitorProvider,
    PartnerProvider
  )
);

const EnhancedApp = enhance(App);

export default EnhancedApp;
