import ApiService from "../api";

export type ICreditProducts = {
  uuid: string;
  name: string;
};

export type IResponseCreditProducts = {
  success: boolean;
  payload: ICreditProducts[];
  error: null;
};

export const getCreditProductAPI = () => {
  return ApiService.HttpGet<IResponseCreditProducts>({
    route: "operation/credit-product/",
    token: true,
  });
};
