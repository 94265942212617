/* eslint-disable @typescript-eslint/no-unsafe-function-type */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Performs LTR (Left-To-Reft) function composition.
 * @example
 * const getName = pipe(
 *   (user: User) => [user.firstName, user.lastName],
 *   ([firstName, lastName]: [string, string]) => `${firstName} ${lastName}`
 * );
 *
 * getName({ firstName: 'Marcos', lastName: 'Vinícius' });
 * //=> "Marcos Vinícius"
 * @param {Array.<function(*):*>} fns - Functions to be composed.
 * @returns {function(*):*}
 */
// prettier-ignore
const pipe = (...fns: Function[]) => (value: any) =>
    fns.reduce((value, fn) => fn(value), value);

export default pipe;
