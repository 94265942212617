import constate from "constate";
import { useState } from "react";

const [DebitorProvider, useDebitor] = constate(() => {
  const [openNewDebitorModal, setOpenNewDebitorModal] =
    useState<boolean>(false);

  return { openNewDebitorModal, setOpenNewDebitorModal };
});

export { DebitorProvider };

export default useDebitor;
