import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import {
  Breadcrumb,
  Button,
  Input,
  InputCurrency,
  InputSelect,
  Tile,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";

import ActivityIcon from "~/assets/Activity.png";
import PrinterIcon from "~/assets/Printer.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import LateralModal from "~/components/LateralModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

/* interface Operation {
  status: string;
  operation: string;
  borrower: string;
  document_number: string;
  amount: string;
  date: string;
  rate: string;
} */

/* const operations: Operation[] = [
  {
    status: "Paid",
    operation: "CCB Clean",
    borrower: "Rhaenyra Targaryen",
    document_number: "000.000.000-00",
    amount: "R$ 1,000.00",
    date: "10/12/2024",
    rate: "1.5%",
  },
  {
    status: "Error",
    operation: "CCB Clean",
    borrower: "Daemon Targaryen",
    document_number: "111.111.111-11",
    amount: "R$ 1,500.00",
    date: "05/11/2024",
    rate: "2.0%",
  },
  {
    status: "Guarantee",
    operation: "CCB Clean",
    borrower: "Aegon Targaryen",
    document_number: "222.222.222-22",
    amount: "R$ 2,000.00",
    date: "15/10/2024",
    rate: "1.8%",
  },
  {
    status: "Paid",
    operation: "CCB Clean",
    borrower: "Viserys Targaryen",
    document_number: "333.333.333-33",
    amount: "R$ 3,000.00",
    date: "12/09/2024",
    rate: "1.7%",
  },
  {
    status: "Error",
    operation: "CCB Clean",
    borrower: "Aemond Targaryen",
    document_number: "444.444.444-44",
    amount: "R$ 750.00",
    date: "22/08/2024",
    rate: "2.5%",
  },
  {
    status: "Guarantee",
    operation: "CCB Clean",
    borrower: "Helaena Targaryen",
    document_number: "555.555.555-55",
    amount: "R$ 1,250.00",
    date: "30/07/2024",
    rate: "1.6%",
  },
  {
    status: "Paid",
    operation: "CCB Clean",
    borrower: "Baela Targaryen",
    document_number: "666.666.666-66",
    amount: "R$ 2,500.00",
    date: "18/06/2024",
    rate: "1.9%",
  },
  {
    status: "Error",
    operation: "CCB Clean",
    borrower: "Rhaenys Targaryen",
    document_number: "777.777.777-77",
    amount: "R$ 1,800.00",
    date: "14/05/2024",
    rate: "2.2%",
  },
  {
    status: "Guarantee",
    operation: "CCB Clean",
    borrower: "Corlys Velaryon",
    document_number: "888.888.888-88",
    amount: "R$ 950.00",
    date: "07/04/2024",
    rate: "1.4%",
  },
  {
    status: "Paid",
    operation: "CCB Clean",
    borrower: "Mysaria",
    document_number: "999.999.999-99",
    amount: "R$ 500.00",
    date: "10/03/2024",
    rate: "1.3%",
  },
]; */

const Simulation = () => {
  const navigate = useNavigate();

  //   const [stateKeytab, setstateKeytab] = useState<number>(0);

  // const [openModal, setOpenModal] = useState<boolean>(false);
  //const canReadAttends = permissions.includes("can_read_attends");
  // const canDeleteAttends = permissions.includes("can_delete_attends")
  // const canEditAttends = permissions.includes("can_edit_attends")
  //const canCreatAttends = permissions.includes("can_create_attends");
  //   const [isParentChecked, setIsParentChecked] = useState(false);
  //   const [isParentIndeterminate, setIsParentIndeterminate] = useState(false);
  /* const [_, setCheckedIndexes] = useState<boolean[]>(
    Array(operations.length).fill(false)
  ); */

  //   const handleCheckboxChange = (rowIndex: number, operation: unknown) => {
  //     // setpayment(payment);
  //     setCheckedIndexes((prev) => {
  //       const updatedChecks = [...prev];
  //       updatedChecks[rowIndex] = !updatedChecks[rowIndex];

  //       const totalChecked = updatedChecks.filter(Boolean).length;
  //       const allChecked = totalChecked === operations.length;
  //       const someChecked = totalChecked > 0;

  //       setIsParentChecked(someChecked);
  //       setIsParentIndeterminate(!allChecked && someChecked);

  //       return updatedChecks;
  //     });
  //   };
  //   const filterCheckds = checkedIndexes.filter((items) => items === true);
  //   const someChecked = checkedIndexes.some((items) => items === true);

  //   const handleParentCheckboxChange = () => {
  //     const newState = !isParentChecked;
  //     setCheckedIndexes(Array(operations.length).fill(newState));
  //     setIsParentChecked(newState);
  //     setIsParentIndeterminate(false);
  //   };

  /* const renderType: { [key: string]: PropsTag["type"] } = {
    Error: "magenta",
    Paid: "green",
    Guarantee: "gray",
  };

  const renderTypeLabel: { [key: string]: string } = {
    Error: "Erro",
    Paid: "Pago",
    Guarantee: "Garantia",
  }; */

  /* const titlesColumn = [
    <Checkbox
    // checked={isParentChecked}
    // indeterminate={isParentIndeterminate}
    // onChange={handleParentCheckboxChange}
    />,

    "Status",
    "Operações",
    "Tomador",
    "CPF / CNPJ",
    "Valor Principal",
    "Data de início",
    "Taxa",
  ]; */

  /* const dataTable = operations?.map((operation: Operation, index: number) => {
    return {
      Checkbox: (
        <Checkbox
          key={index}
          checked={checkedIndexes[index]}
          onClick={() => {
            //   handleCheckboxChange(index, operation); null
          }}
        />
      ),
      Status: (
        <div style={{ maxWidth: "150px" }}>
          {" "}
          <Tag type={renderType[operation.status]}>
            {renderTypeLabel[operation.status]}
          </Tag>
        </div>
      ),
      Operações: operation.operation,
      Tomador: operation.borrower,
      "CPF / CNPJ": operation.document_number,
      "Valor Principal": operation.amount,
      "Data de início": operation.date,
      Taxa: operation.rate,
    };
  }); */

  const canDoSimulations = true;

  useEffect(() => {
    // setCheckedIndexes(Array(operations.length).fill(false));
  }, []);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          { crumb: "Início", onClick: () => navigate("/app") },
          { crumb: "Crédito", onClick: () => navigate("/app/credit") },
          { crumb: "Simulação" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
          Operações de crédito
          </h2> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canDoSimulations && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Margin mb={30} />
                  <Text style={{ fontSize: "25px" }}>Simulação</Text>
                  <Margin mb={20} />
                  <h4>Parametros da operação</h4>
                </div>

                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    kind="tertiary"
                    label="Imprimir"
                    size="large"
                    icon={PrinterIcon}
                  />
                  <Button
                    kind="primary"
                    label="Simular"
                    size="large"
                    icon={ActivityIcon}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gap: "32px",
                  rowGap: "30px",
                  marginTop: "20px",
                }}
              >
                <div>
                  <InputSelect
                    kind="default"
                    size="large"
                    label="Produto"
                    options={[]}
                    placeholder="Selecione uma opção"
                  />
                </div>
                <div>
                  <InputSelect
                    kind="default"
                    size="large"
                    label="Tipo de pessoa"
                    options={[]}
                    placeholder="Selecione uma opção"
                  />
                </div>
                <div>
                  <InputSelect
                    kind="default"
                    size="large"
                    label="Tipo de cálculo"
                    options={[]}
                    placeholder="Selecione uma opção"
                  />
                </div>

                <div style={{ position: "relative", top: "7px" }}>
                  <InputCurrency
                    size="large"
                    label="Valor solicitado (R$)"
                    placeholder="%"
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    maxLength={3}
                    max={3}
                    size="large"
                    label="Taxa de juros (%)"
                    placeholder="%"
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    maxLength={4}
                    max={4}
                    size="large"
                    label="Duração"
                    placeholder="Meses"
                    message="Em meses"
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    maxLength={4}
                    max={4}
                    size="large"
                    label="Carência de amortização"
                    placeholder="Meses"
                    message="Em meses"
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    maxLength={4}
                    max={4}
                    size="large"
                    label="Carência de juros"
                    placeholder="Meses"
                    message="Em meses"
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    maxLength={4}
                    max={4}
                    size="large"
                    label="Periodicidade dos juros"
                    placeholder="%"
                  />
                </div>

                <div>
                  <InputSelect
                    kind="default"
                    size="large"
                    label="Base de cálculo"
                    options={[]}
                    placeholder="Selecione uma opção"
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    size="large"
                    maxLength={3}
                    max={3}
                    label="Correspondente bancário"
                    placeholder="Digite"
                  />
                </div>

                <div>
                  <InputSelect
                    kind="default"
                    size="large"
                    label="Indexador pós-fixado"
                    options={[]}
                    placeholder="Selecione uma opção"
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    size="large"
                    maxLength={3}
                    max={3}
                    label="Percentual indexador (%)"
                    placeholder="%"
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    type="date"
                    size="large"
                    label="Data de início"
                    placeholder="Meses"
                  />
                </div>
                <div style={{ position: "relative", top: "7px" }}>
                  <Input
                    maxLength={2}
                    max={2}
                    size="large"
                    label="Dia de vencimento"
                    placeholder="Digite"
                  />
                </div>
              </div>
              <Margin mt={50} />
              <h4>Resultados da operação</h4>

              <div
                style={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  gap: "32px",
                  rowGap: "30px",
                  marginTop: "20px",
                }}
              >
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>Data de vencimento</h4>
                    <Margin mt={20} />
                    <p>31/12/2024</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>Data de vencimento</h4>
                    <Margin mt={20} />
                    <p>31/12/2024</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>Prazo</h4>
                    <Margin mt={20} />
                    <p>5 Meses</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>IOF</h4>
                    <Margin mt={20} />
                    <p>R$ 1.000,00</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>Valor do contrato</h4>
                    <Margin mt={20} />
                    <p>R$ 10.000,00</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>Valor líquido</h4>
                    <Margin mt={20} />
                    <p>R$ 9.000,00</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>Custo da emissão</h4>
                    <Margin mt={20} />
                    <p>R$ 50,00</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>Indexador</h4>
                    <Margin mt={20} />
                    <p>Sem indexador pós-fixado</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>Taxa A.M.</h4>
                    <Margin mt={20} />
                    <p>10 %</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>Taxa A.A.</h4>
                    <Margin mt={20} />
                    <p>120 %</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>CET A.M.</h4>
                    <Margin mt={20} />
                    <p>10 %</p>
                  </div>
                </Tile>
                <Tile variations="default">
                  {" "}
                  <div>
                    <h4>CET A.A.</h4>
                    <Margin mt={20} />
                    <p>120 %</p>
                  </div>
                </Tile>
              </div>
            </>
          )}
        </>

        {!canDoSimulations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ~visualizar as simulações <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>

      <LateralModal
        toggleModal={() => null}
        open
        title="Novo"
        header
      ></LateralModal>
    </Container>
  );
};

export default Simulation;
