import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import {
  Breadcrumb,
  Button,
  Checkbox,
  TableBatchActions,
  Tag,
} from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";
import filterIcon from "~/assets/Filter.png";
import moneyIcon from "~/assets/Money.png";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import { PropsTag } from "prosperita-dumbo-react/dist/types/Components/Tag";
import { getOperationsAPI, IOperations } from "~/services/api/operations";
import useOperation from "~/hooks/useOperation";
import {
  formatCPFCNPJ,
  formatNumberToCurrencyWithoutSimbol,
} from "~/services/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

const canViewOperations = true;

const renderType: { [key: string]: PropsTag["type"] } = {
  Error: "magenta",
  Paid: "green",
  Guarantee: "gray",
};

const renderTypeLabel: { [key: string]: string } = {
  Error: "Erro",
  Paid: "Pago",
  Guarantee: "Garantia",
};

const titlesColumn = [
  <Checkbox
  // checked={isParentChecked}
  // indeterminate={isParentIndeterminate}
  // onChange={handleParentCheckboxChange}
  />,

  "Status",
  "Operações",
  "Tomador",
  "CPF / CNPJ",
  "Valor Principal",
  "Data de início",
  "Taxa",
];

const Operations = () => {
  const navigate = useNavigate();
  const { operations, setOperations } = useOperation();

  //   const [stateKeytab, setstateKeytab] = useState<number>(0);

  // const [openModal, setOpenModal] = useState<boolean>(false);
  //const canReadAttends = permissions.includes("can_read_attends");
  // const canDeleteAttends = permissions.includes("can_delete_attends")
  // const canEditAttends = permissions.includes("can_edit_attends")
  //const canCreatAttends = permissions.includes("can_create_attends");
  //   const [isParentChecked, setIsParentChecked] = useState(false);
  //   const [isParentIndeterminate, setIsParentIndeterminate] = useState(false);
  const [checkedIndexes, setCheckedIndexes] = useState<boolean[]>(
    Array(operations?.length)?.fill(false)
  );

  //   const handleCheckboxChange = (rowIndex: number, operation: unknown) => {
  //     // setpayment(payment);
  //     setCheckedIndexes((prev) => {
  //       const updatedChecks = [...prev];
  //       updatedChecks[rowIndex] = !updatedChecks[rowIndex];

  //       const totalChecked = updatedChecks.filter(Boolean).length;
  //       const allChecked = totalChecked === operations.length;
  //       const someChecked = totalChecked > 0;

  //       setIsParentChecked(someChecked);
  //       setIsParentIndeterminate(!allChecked && someChecked);

  //       return updatedChecks;
  //     });
  //   };
  //   const filterCheckds = checkedIndexes.filter((items) => items === true);
  //   const someChecked = checkedIndexes.some((items) => items === true);

  //   const handleParentCheckboxChange = () => {
  //     const newState = !isParentChecked;
  //     setCheckedIndexes(Array(operations.length).fill(newState));
  //     setIsParentChecked(newState);
  //     setIsParentIndeterminate(false);
  //   };

  const dataTable = operations?.map((operation: IOperations, index: number) => {
    return {
      Checkbox: (
        <Checkbox
          key={index}
          checked={checkedIndexes[index]}
          onClick={() => {
            //   handleCheckboxChange(index, operation); null
          }}
        />
      ),
      Status: (
        <div style={{ maxWidth: "150px" }}>
          {" "}
          <Tag type={renderType[operation.status]}>
            {renderTypeLabel[operation.status]}
          </Tag>
        </div>
      ),
      Operações: operation?.operation_type,
      Tomador: operation?.debtor_name,
      "CPF / CNPJ": formatCPFCNPJ(operation?.debtor_document),
      "Valor Principal": `R$ ${formatNumberToCurrencyWithoutSimbol(operation?.principal_amount)} `,
      "Data de início": operation?.operation_date,
      Taxa: operation?.interest_rate,
    };
  });

  const fetchOperations = useCallback(async () => {
    try {
      const { data } = await getOperationsAPI();
      setOperations(data.results);
    } catch (e) {
      console.log(e);
    }
  }, [setOperations]);

  useEffect(() => {
    setCheckedIndexes(Array(operations?.length)?.fill(false));
    fetchOperations();
  }, [fetchOperations, setCheckedIndexes, operations?.length]);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          { crumb: "Início", onClick: () => navigate("/app") },
          { crumb: "Crédito", onClick: () => navigate("/app/credit") },
          { crumb: "Operações" },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <h2
            style={{
              margin: "10px 0 0 45px ",
              color: "#000",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: 100,
              lineHeight: "40px",
            }}
          >
          Operações de crédito
          </h2> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <>
          {canViewOperations && operations?.length > 0 ? (
            <>
              <>
                <Margin mt={30} />
                <div>
                  <TableBatchActions
                    search
                    headerAction={{
                      active: false,
                      count: 0,
                    }}
                    columnTitles={titlesColumn}
                    rowData={dataTable}
                    columsKeys={[
                      "",
                      "Checkbox",
                      "Status",
                      "Operações",
                      "Tomador",
                      "CPF / CNPJ",
                      "Valor Principal",
                      "Data de início",
                      "Taxa",
                    ]}
                    pageSizes={[5, 10, 20, 50, 100]}
                    width="1000px"
                    buttons={[
                      {
                        kind: "ghost",
                        label: "",
                        position: "right",
                        icon: filterIcon,
                        width: "40px",
                        dimension: "36",
                      },
                      {
                        kind: "ghost",
                        label: "",
                        position: "right",
                        icon: renewIcon,
                        width: "40px",
                        dimension: "36",
                      },
                      {
                        kind: "primary",
                        label: "Nova Operação",
                        position: "right",

                        onClick: () => navigate("/app/credit/operations/new"),
                      },
                    ]}
                  />
                </div>
              </>
            </>
          ) : (
            canViewOperations && (
              <div>
                <Margin mt={70} />
                <div>
                  <TagLup src={lupaIcon} />
                  <Text>Sem operações</Text>
                  <Subtext>
                    Você não tem operações para serem exibidas no momento
                  </Subtext>
                </div>
                <Margin mt={50} />
                <div style={{ width: "320px" }}>
                  <Button
                    kind="primary"
                    size="medium"
                    label={"Nova operação"}
                    onClick={() => navigate("/app/credit/operations/new")}
                    icon={moneyIcon}
                  />
                </div>
              </div>
            )
          )}
        </>

        {!canViewOperations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para ver as operações de crédito, <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default Operations;
