import Margin from "~/components/Margin";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Breadcrumb,
  Button,
  InputSelect,
  Tab,
  TabPane,
  Input,
  Tooltip,
  InputCurrency,
  TableBasic,
} from "prosperita-dumbo-react";

import moneyIcon from "~/assets/Money.png";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import lockIcon from "~/assets/lock.png";
import styled from "styled-components";
import NewDebitor from "./NewDebitor";
import useDebitor from "~/hooks/useDebitor";
import NewDebitorIcon from "~/assets/new-debitor.png";
import {
  getCreditProductAPI,
  ICreditProducts,
} from "~/services/api/creditProduct";
import useOperation from "~/hooks/useOperation";
import { getPartnersAPI, IPartner } from "~/services/api/partner";
import usePartner from "~/hooks/usePartner";
import {
  getOperationInvolvedsAPI,
  ISimulateOperationBody,
  postSimulateOperationAPI,
} from "~/services/api/operations";
import NewInvolved from "./NewInvolved";
import { useFormik } from "formik";
import { convertStringToNumber } from "~/services/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh; /* Ajusta o container para caber na altura da tela */
  overflow: hidden; /* Remove o scroll */
  align-items: start;
  padding: 30px 50px;
  box-sizing: border-box;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

interface Document {
  name: string;
  documentType: string;
  signatories: string[];
  signatureStatus: string;
  date: string; // Adicionando a propriedade de data
}

const canViewOperations = true;

const titlesColumnPartners = [
  "Nome",
  "Tipo de relação",
  "Relacionado à",
  "Forma de envio",
];

const titlesColumnDocuments = [
  "Nome",
  "Tipo de documento",
  "Signatários",
  "Data de criação",
  "Status da assinatura",
];

type Values = {
  product_uuid: string;
  calculation_type: string;
  requested_amount: string;
  duration: string;
  calculation_base: string;
  operation_date: string;
  release_date: string;
  due_date: string;
  interest_rate_monthly: string;
  interest_periodicity: string;
  amortization_grace_period: string;
  interest_grace_period: string;
  commission: string;
  indexer: string;
  indexer_rate: string;
};

const initialValues: Values = {
  product_uuid: "",
  calculation_type: "",
  requested_amount: "",
  duration: "",
  calculation_base: "",
  operation_date: "",
  release_date: "",
  due_date: "",
  interest_rate_monthly: "",
  interest_periodicity: "",
  amortization_grace_period: "",
  interest_grace_period: "",
  commission: "",
  indexer: "",
  indexer_rate: "",
};

const NewOperations = () => {
  const navigate = useNavigate();
  const { setOpenNewDebitorModal } = useDebitor();
  const [tab, setTab] = useState(0);
  const { setCreditProducts, creditProducts, setAddInvolved } = useOperation();
  const { setPartners, partners } = usePartner();
  //   const [stateKeytab, setstateKeytab] = useState<number>(0);

  // const [openModal, setOpenModal] = useState<boolean>(false);
  //const canReadAttends = permissions.includes("can_read_attends");
  // const canDeleteAttends = permissions.includes("can_delete_attends")
  // const canEditAttends = permissions.includes("can_edit_attends")
  //const canCreatAttends = permissions.includes("can_create_attends");
  //   const [isParentChecked, setIsParentChecked] = useState(false);
  //   const [isParentIndeterminate, setIsParentIndeterminate] = useState(false);
  /*   const [_, setCheckedIndexes] = useState<boolean[]>(
    Array(partners.length).fill(false)
  ); */

  //   const handleCheckboxChange = (rowIndex: number, operation: unknown) => {
  //     // setpayment(payment);
  //     setCheckedIndexes((prev) => {
  //       const updatedChecks = [...prev];
  //       updatedChecks[rowIndex] = !updatedChecks[rowIndex];

  //       const totalChecked = updatedChecks.filter(Boolean).length;
  //       const allChecked = totalChecked === operations.length;
  //       const someChecked = totalChecked > 0;

  //       setIsParentChecked(someChecked);
  //       setIsParentIndeterminate(!allChecked && someChecked);

  //       return updatedChecks;
  //     });
  //   };
  //   const filterCheckds = checkedIndexes.filter((items) => items === true);
  //   const someChecked = checkedIndexes.some((items) => items === true);

  //   const handleParentCheckboxChange = () => {
  //     const newState = !isParentChecked;
  //     setCheckedIndexes(Array(operations.length).fill(newState));
  //     setIsParentChecked(newState);
  //     setIsParentIndeterminate(false);
  //   };

  const { values, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => undefined,
  });

  const dataTablePartners = partners?.map((partner: IPartner) => {
    return {
      Nome: partner.name,
      "Tipo de relação": partner.document_number,
      "Realcionado à": partner.birth_date,
      "Forma de envio": partner.birth_date,
    };
  });

  const dataTableDocuments = []?.map((document: Document) => {
    return {
      Nome: document.name,
      "Tipo de documento": document.documentType,
      Signatários: document.signatories.map((sign) => {
        return <p>{sign} </p>;
      }),
      "Data de criação": document.date,
      "Status da assinatura": document.signatureStatus,
    };
  });

  const creditProductsOptions = useMemo(() => {
    return creditProducts
      .map((creditProduct: ICreditProducts) => ({
        value: creditProduct.uuid,
        label: creditProduct.name,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [creditProducts]);

  const partnersOptions = useMemo(() => {
    return partners
      .map((partner: IPartner) => ({
        value: partner.uuid,
        label: partner.name,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, [partners]);

  const indexesOptions = useMemo(() => {
    return [
      { index: "Selic", value: "selic" },
      { index: "IGP-M", value: "igp-m" },
      { index: "IPCA", value: "ipca" },
      { index: "CDI", value: "cdi" },
    ]
      .map((index) => ({
        value: index.value,
        label: index.index,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const calculationBaseOptions = useMemo(() => {
    return [
      { value: "net_value", label: "Valor Líquido" },
      { value: "gross_value", label: "Valor Bruto" },
    ].sort((a, b) =>
      a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
    );
  }, []);

  const periodicityOptions = useMemo(() => {
    return [
      { label: "Diário", value: "daily" },
      { label: "Semanal", value: "weekly" },
      { label: "Mensal", value: "monthly" },
      { label: "Anual", value: "annually" },
    ]
      .map((periodicity) => ({
        value: periodicity.value,
        label: periodicity.label,
      }))

      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const calculationTypeOptions = useMemo(() => {
    return [
      { value: "360", label: "360 Dias" },
      { value: "365", label: "365 Dias" },
      { value: "252", label: "252 Dias" },
    ]
      .map((type) => ({
        value: type.value,
        label: type.label,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, "pt-BR", { sensitivity: "base" })
      );
  }, []);

  const payloadSimulateOperation: ISimulateOperationBody = useMemo(() => {
    return {
      product_uuid: "6cc03c28-f065-4108-97ea-3f16705d2d85", //desmocar
      calculation_type: values.calculation_base,
      requested_amount: convertStringToNumber(values.requested_amount),
      duration: Number(values.duration),
      calculation_base: values.calculation_base,
      operation_date: values.operation_date,
      release_date: values.release_date,
      due_date: values.due_date,
      taxes_settings: {
        interest_rate_monthly: values.interest_rate_monthly.includes(",")
          ? convertStringToNumber(values.interest_rate_monthly)
          : Number(values.interest_rate_monthly),
        interest_periodicity: values.interest_periodicity,
        amortization_grace_period: Number(values.amortization_grace_period),
        interest_grace_period: Number(values.interest_grace_period),
        commission: values.commission.includes(",")
          ? convertStringToNumber(values.commission)
          : Number(values.commission),
        indexer: values.indexer,
        indexer_rate: values.indexer_rate.includes(",")
          ? convertStringToNumber(values.indexer_rate)
          : Number(values.indexer_rate),
      },
    };
  }, [values]);

  console.log(payloadSimulateOperation);

  const sendSimulateOperation = useCallback(async () => {
    try {
      const { data } = await postSimulateOperationAPI(payloadSimulateOperation);
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  }, [payloadSimulateOperation]);

  const fetchCreditProducts = useCallback(async () => {
    try {
      const { data } = await getCreditProductAPI();
      setCreditProducts(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setCreditProducts]);

  const fetchPartners = useCallback(async () => {
    try {
      const { data } = await getPartnersAPI();
      setPartners(data.payload);
    } catch (e) {
      console.log(e);
    }
  }, [setPartners]);

  const fetchInvolveds = useCallback(async () => {
    try {
      await getOperationInvolvedsAPI();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleSelect = (fieldName, selectedOption) => {
    if (selectedOption) {
      setFieldValue(fieldName, selectedOption.value);
    }
  };

  useEffect(() => {
    fetchCreditProducts();
    fetchPartners();
    fetchInvolveds();
    // setCheckedIndexes(Array(partners.length).fill(false));
  }, []);

  return (
    <Container>
      <Breadcrumb
        crumbsOptions={[
          { crumb: "Início", onClick: () => navigate("/app") },
          { crumb: "Crédito", onClick: () => navigate("/app/credit") },
          { crumb: "Operações" },
          {
            crumb: "Nova operação",
            onClick: () => navigate("/app/credit/operations"),
          },
        ]}
        endHiddenIndex={5}
        startHiddenIndex={5}
      />

      <div style={{ width: "100%" }}>
        <>
          {canViewOperations && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "30px ",
                }}
              >
                <h2
                  style={{
                    color: "#000",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: 100,
                    lineHeight: "40px",
                  }}
                >
                  Nova operação
                </h2>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div style={{ width: "250px" }}>
                    <Button
                      kind="secondary"
                      size="large"
                      label="Simular"
                      onClick={() => navigate("/app/credit/simulation")}
                    />
                  </div>
                  <div style={{ width: "250px" }}>
                    <Button
                      kind="secondary"
                      size="large"
                      label="Recuperar simulação"
                    />
                  </div>
                  <div style={{ width: "250px" }}>
                    <Button
                      kind="primary"
                      size="large"
                      label="Salvar"
                      onClick={() => sendSimulateOperation()}
                    />
                  </div>
                </div>
              </div>
              <div>
                <Tab width="100px">
                  <TabPane
                    label="Informações do produto"
                    isActive={tab === 0}
                    onClick={() => setTab(0)}
                    bordercolor="rgb(10, 74, 135)"
                    width="200px"
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr auto",
                        gap: "32px",
                        rowGap: "30px",
                        marginTop: "20px",
                      }}
                    >
                      <InputSelect
                        // name="product_uuid"
                        kind="default"
                        size="large"
                        label="Produto"
                        options={creditProductsOptions}
                        placeholder="Selecione uma opção"
                        // onChange={(e) => handleSelect("", e)}
                      />
                      <InputSelect
                        kind="default"
                        size="large"
                        label="Cessionário"
                        options={partnersOptions}
                        placeholder="Selecione uma opção"
                      />
                      <InputSelect
                        kind="default"
                        size="large"
                        label="Tomador"
                        options={partnersOptions}
                        placeholder="Selecione uma opção"
                      />{" "}
                      <div
                        style={{
                          position: "relative",
                          top: "23px",
                        }}
                      >
                        <Tooltip
                          align="top-right"
                          size="medium"
                          label="Adicionar tomador"
                        >
                          {" "}
                          <Button
                            size="medium"
                            kind="tertiary"
                            icon={NewDebitorIcon}
                            onClick={() => setOpenNewDebitorModal(true)}
                          />
                        </Tooltip>
                      </div>
                      <InputSelect
                        kind="default"
                        size="large"
                        label="Tipo de liquidação"
                        options={[]}
                        placeholder="Selecione uma opção"
                      />
                      <InputSelect
                        kind="default"
                        size="large"
                        label="Conta de liquidação"
                        options={[]}
                        placeholder="Selecione uma opção"
                      />
                      {/* <div
                        style={{
                          position: "relative",
                          top: "32px",
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        <Checkbox label="Registradora de recebiveis" checked />
                        <Checkbox label="Cessão em lote" />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          top: "23px",
                        }}
                      ></div> */}
                      <div style={{ gridColumn: "1 / -1" }}>
                        <Input
                          type="textarea"
                          rows={5}
                          label="Obeservações"
                          placeholder="Observações adicionais"
                        />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    label="Envolvidos"
                    isActive={tab === 1}
                    onClick={() => setTab(1)}
                    bordercolor="rgb(10, 74, 135)"
                    width="200px"
                  >
                    {" "}
                    <div style={{ width: "100%" }}>
                      <>
                        {canViewOperations && partners?.length > 0 ? (
                          <>
                            <>
                              <Margin mt={30} />
                              <div>
                                <TableBasic
                                  search
                                  columnTitles={titlesColumnPartners}
                                  rowData={dataTablePartners}
                                  pageSizes={[5, 10, 20, 50, 100]}
                                  width="1000px"
                                  buttons={[
                                    {
                                      kind: "ghost",
                                      label: "",
                                      position: "right",
                                      icon: renewIcon,
                                      width: "40px",
                                      dimension: "36",
                                    },
                                    {
                                      kind: "primary",
                                      label: "Adicionar envolvido",
                                      position: "right",

                                      onClick: () => setAddInvolved(true),
                                    },
                                  ]}
                                />
                              </div>
                            </>
                          </>
                        ) : (
                          canViewOperations && (
                            <div>
                              <Margin mt={70} />
                              <div>
                                <TagLup src={lupaIcon} />
                                <Text>Sem operações</Text>
                                <Subtext>
                                  Você não tem operações para serem exibidas no
                                  momento
                                </Subtext>
                              </div>
                              <Margin mt={50} />
                              <div style={{ width: "320px" }}>
                                <Button
                                  kind="primary"
                                  size="medium"
                                  label={"Nova operação"}
                                  onClick={() =>
                                    navigate("/app/credit/operations/new")
                                  }
                                  icon={moneyIcon}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </>
                    </div>
                  </TabPane>
                  <TabPane
                    label="Operação"
                    isActive={tab === 2}
                    onClick={() => setTab(2)}
                    bordercolor="rgb(10, 74, 135)"
                    width="200px"
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr 1fr 1fr",
                          gap: "32px",
                          rowGap: "30px",
                          marginTop: "20px",
                        }}
                      >
                        <div>
                          <InputSelect
                            name="calculation_type"
                            kind="default"
                            size="large"
                            label="Tipo de cálculo"
                            options={calculationTypeOptions}
                            placeholder="Selecione uma opção"
                            onChange={(e) =>
                              handleSelect("calculation_type", e)
                            }
                          />
                        </div>
                        <div style={{ position: "relative", top: "7px" }}>
                          <InputCurrency
                            name="requested_amount"
                            id="requested_amount"
                            size="large"
                            label="Valor solicitado (R$)"
                            placeholder="R$"
                            value={values.requested_amount}
                            onChange={(e) =>
                              setFieldValue("requested_amount", e.target.value)
                            }
                          />
                        </div>
                        <div style={{ position: "relative", top: "7px" }}>
                          <Input
                            name="interest_rate_monthly"
                            id="interest_rate_monthly"
                            maxLength={5}
                            max={5}
                            size="large"
                            label="Taxa de juros (%)"
                            placeholder="%"
                            value={values.interest_rate_monthly}
                            onChange={(e) => {
                              let value = e.target.value;

                              value = value.replace(/[^0-9,\\.]/g, "");
                              setFieldValue("interest_rate_monthly", value);
                            }}
                          />
                        </div>
                        <div>
                          <InputSelect
                            name="interest_periodicity"
                            id="interest_periodicity"
                            kind="default"
                            size="large"
                            label="Periodicidade dos juros"
                            options={periodicityOptions}
                            placeholder="Selecione uma opção"
                            onChange={(e) =>
                              handleSelect("interest_periodicity", e)
                            }
                          />
                        </div>
                        <div style={{ position: "relative", top: "7px" }}>
                          <Input
                            name="duration"
                            id="duration"
                            maxLength={3}
                            max={3}
                            size="large"
                            label="Duração"
                            value={values.duration}
                            placeholder="Meses"
                            message="Em meses"
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.replace(/[^0-9]/g, "");
                              setFieldValue("duration", value);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", top: "7px" }}>
                          <Input
                            name="amortization_grace_period"
                            id="amortization_grace_period"
                            maxLength={3}
                            max={3}
                            size="large"
                            label="Carência de amortização"
                            placeholder="Meses"
                            message="Em meses"
                            value={values.amortization_grace_period}
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.replace(/[^0-9]/g, "");
                              setFieldValue("amortization_grace_period", value);
                            }}
                          />
                        </div>
                        <div style={{ position: "relative", top: "7px" }}>
                          <Input
                            name="interest_grace_period"
                            id="interest_grace_period"
                            maxLength={3}
                            max={3}
                            size="large"
                            label="Carência de juros"
                            placeholder="Meses"
                            value={values.interest_grace_period}
                            message="Em meses"
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.replace(/[^0-9]/g, "");
                              setFieldValue("interest_grace_period", value);
                            }}
                          />
                        </div>

                        <div>
                          <InputSelect
                            name="calculation_base"
                            kind="default"
                            size="large"
                            label="Base de cálculo"
                            options={calculationBaseOptions}
                            placeholder="Selecione uma opção"
                            onChange={(e) =>
                              handleSelect("calculation_base", e)
                            }
                          />
                        </div>

                        <div style={{ position: "relative", top: "7px" }}>
                          <Input
                            name="operation_date"
                            type="date"
                            size="large"
                            label="Data de início"
                            value={values.operation_date}
                            placeholder="Meses"
                            onChange={(e) =>
                              setFieldValue("operation_date", e.target.value)
                            }
                          />
                        </div>
                        <div style={{ position: "relative", top: "7px" }}>
                          <Input
                            type="date"
                            name="release_date"
                            size="large"
                            label="Data de lançamento"
                            placeholder="Digite"
                            value={values.release_date}
                            onChange={(e) =>
                              setFieldValue("release_date", e.target.value)
                            }
                          />
                        </div>
                        <div style={{ position: "relative", top: "7px" }}>
                          <Input
                            type="date"
                            name="due_date"
                            size="large"
                            label="Data de vencimento"
                            placeholder="Digite"
                            value={values.due_date}
                            onChange={(e) =>
                              setFieldValue("due_date", e.target.value)
                            }
                          />
                        </div>
                        <div style={{ position: "relative", top: "7px" }}>
                          <Input
                            name="commission"
                            id="commission"
                            size="large"
                            maxLength={5}
                            max={5}
                            label="Correspondente bancário"
                            placeholder="%"
                            value={values.commission}
                            onChange={(e) => {
                              let value = e.target.value;

                              value = value.replace(/[^0-9,\\.]/g, "");
                              setFieldValue("commission", value);
                            }}
                          />
                        </div>

                        <div>
                          <InputSelect
                            name="indexer"
                            kind="default"
                            size="large"
                            label="Indexador pós-fixado"
                            options={indexesOptions}
                            placeholder="Selecione uma opção"
                            onChange={(e) => handleSelect("indexer", e)}
                          />
                        </div>
                        <div style={{ position: "relative", top: "7px" }}>
                          <Input
                            name="indexer_rate"
                            size="large"
                            maxLength={5}
                            max={5}
                            label="Percentual indexador (%)"
                            placeholder="%"
                            onChange={(e) => {
                              let value = e.target.value;
                              value = value.replace(/[^0-9,\\.]/g, "");
                              setFieldValue("indexer_rate", value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane
                    label="Documentos"
                    isActive={tab === 3}
                    onClick={() => setTab(3)}
                    bordercolor="rgb(10, 74, 135)"
                    width="200px"
                  >
                    <div style={{ width: "100%" }}>
                      <>
                        {canViewOperations && partners?.length > 0 ? (
                          <>
                            <>
                              <Margin mt={30} />
                              <div>
                                <TableBasic
                                  search
                                  columnTitles={titlesColumnDocuments}
                                  rowData={dataTableDocuments}
                                  pageSizes={[5, 10, 20, 50, 100]}
                                  width="1000px"
                                  buttons={[
                                    {
                                      kind: "ghost",
                                      label: "",
                                      position: "right",
                                      icon: renewIcon,
                                      width: "40px",
                                      dimension: "36",
                                    },
                                    {
                                      kind: "primary",
                                      label: "Adicionar documento",
                                      position: "right",

                                      onClick: () =>
                                        navigate("/app/credit/operations/new"),
                                    },
                                  ]}
                                />
                              </div>
                            </>
                          </>
                        ) : (
                          canViewOperations && (
                            <div>
                              <Margin mt={70} />
                              <div>
                                <TagLup src={lupaIcon} />
                                <Text>Sem operações</Text>
                                <Subtext>
                                  Você não tem operações para serem exibidas no
                                  momento
                                </Subtext>
                              </div>
                              <Margin mt={50} />
                              <div style={{ width: "320px" }}>
                                <Button
                                  kind="primary"
                                  size="medium"
                                  label={"Nova operação"}
                                  onClick={() =>
                                    navigate("/app/credit/operations/new")
                                  }
                                  icon={moneyIcon}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </>
                    </div>
                  </TabPane>
                </Tab>
              </div>
            </div>
          )}
        </>

        {!canViewOperations && (
          <>
            <div>
              <Margin mt={70} />
              <TagLup src={lockIcon} />
              <Margin mt={30} />
              <Text>Acesso restrito</Text>

              <Subtext>
                Você não tem permissão para criar operações de crédito, <br />{" "}
                contate o administrador do sistema para mais informações.
              </Subtext>
            </div>
          </>
        )}
      </div>
      <NewDebitor />
      <div style={{ zIndex: 9999 }}>
        <NewInvolved />
      </div>
    </Container>
  );
};

export default NewOperations;
