import ApiService from "../api";

export type IOperations = {
  status: string;
  operation_type: string;
  debtor_name: string;
  debtor_document: string;
  principal_amount: number;
  operation_date: string;
  interest_rate: number;
};

export type IResponseOperations = {
  success: boolean;
  results: IOperations[];
  error: null;
};

export type IOperationBody = {
  operation_uuid: string;
  provider_uuid: string;
  assignor_uuid: string;
};

export type ISimulateOperation = {
  uuid: string;
  product: unknown;
  status: string;
  calculation_base: string;
  calculation_type: string;
  requested_amount: string;
  face_amount: string;
  pre_approved_amount: string;
  payable_amount: string;
  net_amount: string;
  initial_debt_amount: string;
  pmt: string;
  taxes_settings: unknown;
  taxes_values: unknown;
  operation_date: string;
  due_date: string;
  duration: string;
  number_of_installments: string;
};

export type IResponseSimulateOperations = {
  success: boolean;
  payload: ISimulateOperation[];
  error: null;
};

export type ISimulateOperationBody = {
  product_uuid: string;
  calculation_type: string;
  requested_amount: number;
  duration: number;
  calculation_base: string;
  operation_date: string;
  release_date: string;
  due_date: string;
  taxes_settings: {
    interest_rate_monthly: number;
    interest_periodicity: string;
    amortization_grace_period: number;
    interest_grace_period: number;
    commission: number;
    indexer: string;
    indexer_rate: number;
  };
};

export type IOperationInvolved = {
  uuid: string;
  product: unknown;
  status: string;
  calculation_base: string;
  calculation_type: string;
  requested_amount: string;
  face_amount: string;
  pre_approved_amount: string;
  payable_amount: string;
  net_amount: string;
  initial_debt_amount: string;
  pmt: string;
  taxes_settings: unknown;
  taxes_values: unknown;
  operation_date: string;
  due_date: string;
  duration: string;
  number_of_installments: string;
};

export type IResponseOperationInvolveds = {
  success: boolean;
  payload: ISimulateOperation[];
  error: null;
};

export type IOperationInvolvedBody = {
  product_uuid: string;
  calculation_type: string;
  requested_amount: number;
  duration: number;
  calculation_base: string;
  operation_date: string;
  due_date: string;
  asset_type: string;
  taxes_settings: unknown;
};

export const getOperationsAPI = () => {
  return ApiService.HttpGet<IResponseOperations>({
    route: "asset/",
    token: true,
  });
};

export const postOperationAPI = (data: IOperationBody) => {
  return ApiService.HttpPost<IOperations>({
    route: "asset/",
    body: data,
    token: true,
  });
};

export const getSimulateOperationsAPI = () => {
  return ApiService.HttpGet<IResponseSimulateOperations>({
    route: "operation/simulate/",
    token: true,
  });
};

export const postSimulateOperationAPI = (data: ISimulateOperationBody) => {
  return ApiService.HttpPost<ISimulateOperation>({
    route: "operation/simulate/",
    body: data,
    token: true,
  });
};

export const getOperationInvolvedsAPI = () => {
  return ApiService.HttpGet<IResponseOperationInvolveds>({
    route: "asset/involved/",
    token: true,
  });
};
