import Margin from "~/components/Margin";
import {
  Breadcrumb,
  Button,
  Input,
  InputSelect,
  Modal,
  TableBatchActions,
  Tag,
} from "prosperita-dumbo-react";
import filterIcon from "~/assets/Filter.png";
import moneyIcon from "~/assets/Money.png";
import renewIcon from "~/assets/Renew.png";
import lupaIcon from "~/assets/lupa.png";
import lockIcon from "~/assets/lock.png";
import cleanIcon from "~/assets/clean.png";
import arrowRightIcon from "~/assets/arrow-right.png";
import { PropsTag } from "prosperita-dumbo-react/dist/types/Components/Tag";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMemo, useState } from "react";
import { Column, Grid, ProgressBar } from "@carbon/react";
import { Formik, FormikProps } from "formik";
import { removeEmptyFields } from "~/services/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  padding: 30px 50px;
`;

const TagLup = styled.img`
  object-fit: contain;
  width: 200px;
  margin: 20px 0;
`;

const Text = styled.h4`
  color: #161616;
  font-family: "IBM Plex Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 20px;
`;

const Subtext = styled.h4`
  color: #161616;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 20px;
`;

interface Operation {
  status: string;
  correspondent: string;
  assignment_number: string;
  assignee: string;
  assignment_date: string;
  assignment_value: string;
  present_value: string;
  agio: string;
}

const operations: Operation[] = [
  {
    status: "Completed",
    correspondent: "Content",
    assignment_number: "Content",
    assignee: "Content",
    assignment_date: "31/12/2024",
    assignment_value: "R$ 1000,00",
    present_value: "R$ 1000,00",
    agio: "Content",
  },
  {
    status: "Cancelled",
    correspondent: "Content",
    assignment_number: "Content",
    assignee: "Content",
    assignment_date: "31/12/2024",
    assignment_value: "R$ 1000,00",
    present_value: "R$ 1000,00",
    agio: "Content",
  },
  {
    status: "Status",
    correspondent: "Content",
    assignment_number: "Content",
    assignee: "Content",
    assignment_date: "31/12/2024",
    assignment_value: "R$ 1000,00",
    present_value: "R$ 1000,00",
    agio: "Content",
  },
  {
    status: "Completed",
    correspondent: "Content",
    assignment_number: "Content",
    assignee: "Content",
    assignment_date: "31/12/2024",
    assignment_value: "R$ 1000,00",
    present_value: "R$ 1000,00",
    agio: "Content",
  },
  {
    status: "Cancelled",
    correspondent: "Content",
    assignment_number: "Content",
    assignee: "Content",
    assignment_date: "31/12/2024",
    assignment_value: "R$ 1000,00",
    present_value: "R$ 1000,00",
    agio: "Content",
  },
  {
    status: "Status",
    correspondent: "Content",
    assignment_number: "Content",
    assignee: "Content",
    assignment_date: "31/12/2024",
    assignment_value: "R$ 1000,00",
    present_value: "R$ 1000,00",
    agio: "Content",
  },
  {
    status: "Completed",
    correspondent: "Content",
    assignment_number: "Content",
    assignee: "Content",
    assignment_date: "31/12/2024",
    assignment_value: "R$ 1000,00",
    present_value: "R$ 1000,00",
    agio: "Content",
  },
  {
    status: "Cancelled",
    correspondent: "Content",
    assignment_number: "Content",
    assignee: "Content",
    assignment_date: "31/12/2024",
    assignment_value: "R$ 1000,00",
    present_value: "R$ 1000,00",
    agio: "Content",
  },
  {
    status: "Status",
    correspondent: "Content",
    assignment_number: "Content",
    assignee: "Content",
    assignment_date: "31/12/2024",
    assignment_value: "R$ 1000,00",
    present_value: "R$ 1000,00",
    agio: "Content",
  },
  {
    status: "Completed",
    correspondent: "Content",
    assignment_number: "Content",
    assignee: "Content",
    assignment_date: "31/12/2024",
    assignment_value: "R$ 1000,00",
    present_value: "R$ 1000,00",
    agio: "Content",
  },
];

const filterKeyDict = {
  status: "Status",
  assignee: "Cessionário",
  assignment_date_from: "Data da cessão de",
  assignment_date_to: "Data da cessão até",
  assignment_value_min: "Valor da cessão minimo",
  assignment_value_max: "Valor da cessão maximo",
  present_value_min: "Valor presente minimo",
  present_value_max: "Valor presente maximo",
  calculation_value_min: "Valor de cálculo minimo",
  calculation_value_max: "Valor de cálculo maximo",
  operation_number: "Número da operação",
  assignment_number: "Número da cessão",
};

type FormValues = {
  status: string;
  assignee: string;
  assignment_date_from: null;
  assignment_date_to: null;
  assignment_value_min: string;
  assignment_value_max: string;
  present_value_min: string;
  present_value_max: string;
  calculation_value_min: string;
  calculation_value_max: string;
  operation_number: string;
  assignment_number: string;
};

const initialValues: FormValues = {
  status: "",
  assignee: "",
  assignment_date_from: null,
  assignment_date_to: null,
  assignment_value_min: "",
  assignment_value_max: "",
  present_value_min: "",
  present_value_max: "",
  calculation_value_min: "",
  calculation_value_max: "",
  operation_number: "",
  assignment_number: "",
};

const Cessions = () => {
  const navigate = useNavigate();

  const [currentDate, setCurrentDate] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  );

  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toLocaleString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  );

  const [appliedAssignmentsFilters, setAppliedAssignmentsFilters] =
    useState<FormValues>(initialValues);

  const [isAssignmentsFilterModalOpen, setIsAssignmentsFilterModalOpen] =
    useState(false);

  const updateDateTime = () => {
    setCurrentDate(
      new Date().toLocaleString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    );
    setCurrentTime(
      new Date().toLocaleString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    );
  };

  const renderType: { [key: string]: PropsTag["type"] } = {
    Cancelled: "magenta",
    Completed: "green",
    Status: "gray",
  };

  const renderTypeLabel: { [key: string]: string } = {
    Cancelled: "Cancelada",
    Completed: "Concluída",
    Status: "Status",
  };

  const titlesColumn = [
    "Status",
    "Correspondente",
    "Número da cessão",
    "Cessionário",
    "Data da cessão",
    "Valor da cessão",
    "Valor presente",
    "Ágio",
  ];

  const canViewOperations = true;

  function handleOpenAssignmentsFilterModal() {
    setIsAssignmentsFilterModalOpen(true);
  }
  function handleCloseAssignmentsFilterModal() {
    setIsAssignmentsFilterModalOpen(false);
  }

  const handleApplyAssignmentsFilters = (values: FormValues) => {
    setAppliedAssignmentsFilters(values);
    setIsAssignmentsFilterModalOpen(false);
  };

  const handleClearAssignmentsFilters = () => {
    setAppliedAssignmentsFilters(initialValues);
  };

  const filteredFilters = useMemo(() => {
    return removeEmptyFields(appliedAssignmentsFilters);
  }, [appliedAssignmentsFilters]);

  console.log(filteredFilters);

  const dataTable = operations?.map((operation: Operation) => {
    return {
      Status: (
        <div style={{ maxWidth: "150px" }}>
          {" "}
          <Tag type={renderType[operation.status]}>
            {renderTypeLabel[operation.status]}
          </Tag>
        </div>
      ),
      Correspondent: operation.correspondent,
      Assignment_number: operation.assignment_number,
      Assignee: operation.assignee,
      Assignment_date: operation.assignment_date,
      Assignment_value: operation.assignment_value,
      Present_value: operation.present_value,
      Agio: operation.agio,
    };
  });

  const renderFilters = () => {
    const filterKeys = Object.keys(filteredFilters);

    return (
      Object.keys(filteredFilters).length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginBottom: "16px",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            {filterKeys.map((key) => {
              const filterKey = filterKeyDict[key] || key;

              const values = Array.isArray(filteredFilters[key])
                ? filteredFilters[key]
                    .map((item) => item.value)
                    .reduce((acc, curr) => `${acc}, ${curr}`)
                : filteredFilters[key];

              return (
                <Tag type="blue">
                  {filterKey}: {values}
                </Tag>
              );
            })}
          </div>
          <Button
            kind="tertiary"
            label="Limpar tudo"
            icon={cleanIcon}
            onClick={handleClearAssignmentsFilters}
            size="small"
          />
        </div>
      )
    );
  };

  return (
    <>
      <Container>
        <Breadcrumb
          crumbsOptions={[
            { crumb: "Início", onClick: () => navigate("/app") },
            { crumb: "Crédito", onClick: () => navigate("/app/credit") },
            { crumb: "Cessões", onClick: () => navigate("/app/assignments") },
          ]}
          endHiddenIndex={5}
          startHiddenIndex={5}
        />

        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          ></div>
        </div>
        <div style={{ width: "100%" }}>
          <>
            {canViewOperations && operations?.length > 0 ? (
              <>
                <Margin mt={32} />

                {renderFilters()}

                <>
                  <div>
                    <TableBatchActions
                      search
                      headerAction={{
                        active: false,
                        count: 0,
                      }}
                      columnTitles={titlesColumn}
                      rowData={dataTable}
                      columsKeys={[
                        "",
                        "Status",
                        "Correspondent",
                        "Assignment_number",
                        "Assignee",
                        "Assignment_date",
                        "Assignment_value",
                        "Present_value",
                        "Agio",
                      ]}
                      label="Cessões"
                      pageSizes={[5, 10, 15, 20, 50, 100]}
                      width="1000px"
                      actions={[
                        {
                          text: "Baixa de cobrança",
                          icon: moneyIcon,
                        },
                        {
                          text: "Editar boleto",
                          feature: true,
                        },
                        {
                          text: "Cancelar",
                          icon: "",
                        },
                      ]}
                      buttons={[
                        {
                          kind: "ghost",
                          label: "",
                          position: "right",
                          icon: filterIcon,
                          width: "40px",
                          dimension: "36",
                          onClick: handleOpenAssignmentsFilterModal,
                        },
                        {
                          kind: "ghost",
                          label: "",
                          position: "right",
                          icon: renewIcon,
                          width: "40px",
                          dimension: "36",
                          onClick: updateDateTime,
                        },
                      ]}
                    />
                  </div>
                </>
              </>
            ) : (
              canViewOperations && (
                <div>
                  <Margin mt={50} />
                  <div>
                    <TagLup src={lupaIcon} />
                    <Text>Sem cessões</Text>
                    <Subtext>
                      Você não tem cessões para serem exibidas no momento
                    </Subtext>
                  </div>
                  <Margin mt={50} />
                  <div style={{ width: "320px" }}>
                    <Button
                      kind="primary"
                      size="medium"
                      label={"Atualizar"}
                      onClick={() => navigate("/app/credit/operations/new")}
                      icon={moneyIcon}
                    />
                  </div>
                </div>
              )
            )}
          </>

          {!canViewOperations && (
            <>
              <div>
                <Margin mt={70} />
                <TagLup src={lockIcon} />
                <Margin mt={30} />
                <Text>Acesso restrito</Text>

                <Subtext>
                  Você não tem permissão para visualizar as cessões.
                </Subtext>
              </div>
            </>
          )}
        </div>
        <div id="lastUpdate">
          <Margin all={16}>
            <h5>
              Atualizado em{" "}
              <span style={{ fontWeight: "bold" }}>{currentDate}</span>, às{" "}
              <span style={{ fontWeight: "bold" }}>{currentTime}h</span>
            </h5>
          </Margin>
        </div>
      </Container>

      <Formik
        initialValues={initialValues}
        onSubmit={handleApplyAssignmentsFilters}
      >
        {({
          values,
          resetForm,
          submitForm,
          setFieldValue,
        }: FormikProps<FormValues>) => (
          <Modal
            label="Filtros"
            background="#FFFFFF"
            open={isAssignmentsFilterModalOpen}
            onClose={handleCloseAssignmentsFilterModal}
          >
            <ProgressBar label={""} value={25} size="small" />
            <Grid fullWidth style={{ padding: 0 }}>
              <Column lg={16}>
                <Margin mb={32} />
              </Column>

              <Column lg={8} noGutter>
                <InputSelect
                  isMulti
                  kind="default"
                  label="Status"
                  message="Warning message goes here"
                  onChange={(event) => {
                    setFieldValue("status", event);
                  }}
                  value={values.status}
                  options={[
                    {
                      label: "Concluida",
                      value: "Value1",
                    },
                    {
                      label: "Cancelada",
                      value: "Value2",
                    },
                    {
                      label: "Status",
                      value: "Value3",
                    },
                  ]}
                  placeholder="Escolha uma opção"
                  size="large"
                />
              </Column>
              <Column lg={8}>
                <InputSelect
                  isMulti
                  kind="default"
                  label="Cessionário"
                  message="Warning message goes here"
                  onChange={(selected) => setFieldValue("assignee", selected)}
                  value={values.assignee}
                  options={[
                    {
                      label: "Opção 1",
                      value: "Value1",
                    },
                    {
                      label: "Opção 2",
                      value: "Value3",
                    },
                  ]}
                  placeholder="Escolha uma opção"
                  size="large"
                />
              </Column>

              <Column lg={16}>
                <Margin mb={40} />
              </Column>

              <Column lg={16}>
                <h4>Data da cessão</h4>
                <Margin mb={16} />
              </Column>

              <Column lg={8}>
                <Input
                  label="De"
                  placeholder="Placeholder Text"
                  size="large"
                  type="date"
                  onChange={(e) =>
                    setFieldValue("assignment_date_from", e.target.value)
                  }
                  value={values.assignment_date_from || ""}
                />
              </Column>
              <Column lg={8}>
                <Input
                  label="Até"
                  placeholder="Placeholder Text"
                  size="large"
                  type="date"
                  onChange={(e) =>
                    setFieldValue("assignment_date_to", e.target.value)
                  }
                  value={values.assignment_date_to || ""}
                />
              </Column>

              <Column lg={16}>
                <Margin mb={40} />
              </Column>

              <Column lg={16}>
                <h4>Valor da cessão</h4>
                <Margin mb={16} />
              </Column>

              <Column lg={8}>
                <Input
                  label="Valor minimo"
                  placeholder="R$"
                  size="large"
                  type="text"
                  onChange={(e) =>
                    setFieldValue("assignment_value_min", e.target.value)
                  }
                  value={values.assignment_value_min}
                />
              </Column>
              <Column lg={8}>
                <Input
                  label="Valor maximo"
                  placeholder="R$"
                  size="large"
                  type="text"
                  onChange={(e) =>
                    setFieldValue("assignment_value_max", e.target.value)
                  }
                  value={values.assignment_value_max}
                />
              </Column>

              <Column lg={16}>
                <Margin mb={40} />
              </Column>

              <Column lg={16}>
                <h4>Valor presente</h4>
                <Margin mb={16} />
              </Column>

              <Column lg={8}>
                <Input
                  label="Valor minimo"
                  placeholder="R$"
                  size="large"
                  type="text"
                  onChange={(e) =>
                    setFieldValue("present_value_min", e.target.value)
                  }
                  value={values.present_value_min}
                />
              </Column>
              <Column lg={8}>
                <Input
                  label="Valor maximo"
                  placeholder="R$"
                  size="large"
                  type="text"
                  onChange={(e) =>
                    setFieldValue("present_value_max", e.target.value)
                  }
                  value={values.present_value_max}
                />
              </Column>

              <Column lg={16}>
                <Margin mb={40} />
              </Column>

              <Column lg={16}>
                <h4>Valor de cálculo</h4>
                <Margin mb={16} />
              </Column>

              <Column lg={8}>
                <Input
                  label="Valor minimo"
                  placeholder="R$"
                  size="large"
                  type="text"
                  onChange={(e) =>
                    setFieldValue("calculation_value_min", e.target.value)
                  }
                  value={values.calculation_value_min}
                />
              </Column>
              <Column lg={8}>
                <Input
                  label="Valor maximo"
                  placeholder="R$"
                  size="large"
                  type="text"
                  onChange={(e) =>
                    setFieldValue("calculation_value_max", e.target.value)
                  }
                  value={values.calculation_value_max}
                />
              </Column>

              <Column lg={16}>
                <Margin mb={40} />
              </Column>

              <Column lg={16}>
                <h4>Outros tipos de filtros</h4>
                <Margin mb={16} />
              </Column>

              <Column lg={8}>
                <Input
                  label="Número da operação"
                  placeholder="#"
                  size="large"
                  type="text"
                  onChange={(e) =>
                    setFieldValue("operation_number", e.target.value)
                  }
                  value={values.operation_number}
                />
              </Column>
              <Column lg={8}>
                <Input
                  label="Número da cessão"
                  placeholder="#"
                  size="large"
                  type="text"
                  onChange={(e) =>
                    setFieldValue("assignment_number", e.target.value)
                  }
                  value={values.assignment_number}
                />
              </Column>

              <Column lg={16}>
                <Margin mb={32} />
              </Column>

              <Column lg={16}>
                <Margin mb={48} />
              </Column>

              <Column lg={8}>
                <Button
                  kind="tertiary"
                  label="Limpar tudo"
                  icon={cleanIcon}
                  onClick={() => {
                    resetForm();
                  }}
                  size="large"
                />
              </Column>
              <Column lg={8}>
                <Button
                  kind="primary"
                  label="Aplicar filtros"
                  icon={arrowRightIcon}
                  onClick={submitForm}
                  size="large"
                />
              </Column>

              <Column lg={16}>
                <Margin mb={48} />
              </Column>
            </Grid>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default Cessions;
