import {
  Header,
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderMenu,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  Section,
  SideNav,
  SkipToContent,
} from "@carbon/react";
import {
  Notification,
  Search,
  Switcher,
  UserAvatar,
} from "@carbon/icons-react";
import "@fontsource/ibm-plex-sans";
//import { Badge, Logout } from "@carbon/icons-react";
import styled from "styled-components";
import Margin from "~/components/Margin";
import { Outlet, useNavigate } from "react-router-dom"; //useLocation
import useAuth from "~/hooks/useAuth";

import { useEffect, useState } from "react";

import "prosperita-dumbo-react/dist/style.css";
//import { UIHeaderNav } from "prosperita-dumbo-react";
import "prosperita-dumbo-react/dist/style.css";

const DashboardContainer = styled.div`
  min-height: 100vh;

  .cds--header--navigation {
    top: 48px;

    nav {
      padding-left: 0;
    }
  }
  .header {
    z-index: 1;
  }
`;

type MenuItem = {
  label: string;
  route?: string;
  children?: MenuItem[];
};

const menuItems: MenuItem[] = [
  {
    label: "Painel",
    route: "/app/panel",
  },
  // {
  //   label: "Transferência",
  //   route: "/app/transfers",
  // },
  {
    label: "Crédito",
    route: "/app/credit",
    children: [
      {
        label: "Operações",
        route: "/app/credit/operations",
      },
      {
        label: "Simular",
        route: "/app/credit/simulation",
      },
      {
        label: "Cessões",
        route: "/app/credit/assignments",
      },
      {
        label: "Excluídos",
        route: "/app/credit/excludeds",
      },
    ],
  },
  // {
  //   label: "Cobranças",
  //   route: "/app/billing",
  //   children: [
  //     {
  //       label: "Sub-link 1",
  //       route: "/app/billing/sub-link-1",
  //     },
  //     {
  //       label: "Sub-link 2",
  //       route: "/app/billing/sub-link-2",
  //     },
  //     {
  //       label: "Sub-link 3",
  //       route: "/app/billing/sub-link-3",
  //     },
  //   ],
  // },
  // {
  //   label: "Pagamentos",
  //   route: "/app/payments",
  // },
  // {
  //   label: "Mais opções",
  //   route: "/app/more-options",
  //   children: [
  //     {
  //       label: "Sub-link 1",
  //       route: "/app/more-options/sub-link-1",
  //     },
  //   ],
  // },
];

const ResetCss = styled.div``;

const DashboardStructure = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const route = location.pathname;

  const { logout } = useAuth();

  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);

  const onClickSideNavExpand = () => {
    setIsSideNavExpanded(!isSideNavExpanded);
  };

  const handleSearchClick = () => {
    console.log("Search clicked");
    // Lógica para a busca
  };

  const handleNotificationClick = () => {
    console.log("Notification clicked");
    // Lógica para notificações
  };

  const handleAppSwitcherClick = () => {
    console.log("App Switcher Clicker");
    // Lógica para alternância de aplicativos
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  // const canReadTasks = permissions.includes("can_read_tasks");

  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toLocaleString("pt-BR", { hour: "2-digit", minute: "2-digit" })
  );
  console.log(currentTime);
  console.log(handleLogout);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(
        new Date().toLocaleString("pt-BR", {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    }, 1000); // Atualiza a cada segundo
    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar
  }, []);

  const renderNavigationMenu = () => {
    return (
      <HeaderNavigation aria-label="Prosperità [CREDENGINE]">
        {menuItems.map((item, itemIdx) => {
          if (item.children) {
            return (
              <HeaderMenu aria-label={item.label} menuLinkName={item.label}>
                {item.children.map((child, childIdx) => {
                  return (
                    <HeaderMenuItem key={childIdx} href={child.route}>
                      {child.label}
                    </HeaderMenuItem>
                  );
                })}
              </HeaderMenu>
            );
          }

          return (
            <HeaderMenuItem key={itemIdx} href={item.route}>
              {item.label}
            </HeaderMenuItem>
          );
        })}
      </HeaderNavigation>
    );
  };

  return (
    <>
      <ResetCss>
        <>
          <Header aria-label="Prosperità [CREDENGINE]">
            <SkipToContent />
            <HeaderMenuButton
              aria-label={isSideNavExpanded ? "Close menu" : "Open menu"}
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
              aria-expanded={isSideNavExpanded}
            />
            <HeaderName href="#" prefix="Prosperita">
              [CREDENGINE]
            </HeaderName>
            {renderNavigationMenu()}

            <HeaderGlobalBar>
              <HeaderGlobalAction
                aria-label="Search"
                onClick={handleSearchClick}
              >
                <Search size={20} />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                aria-label="Notifications"
                onClick={handleNotificationClick}
              >
                <Notification size={20} />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                aria-label="Profile"
                onClick={handleAppSwitcherClick}
                tooltipAlignment="end"
              >
                <UserAvatar size={20} />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                aria-label="App Switcher"
                onClick={handleAppSwitcherClick}
                tooltipAlignment="end"
              >
                <Switcher size={20} />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
            <SideNav
              aria-label="Side navigation"
              expanded={isSideNavExpanded}
              isPersistent={false}
              onSideNavBlur={onClickSideNavExpand}
            ></SideNav>
          </Header>
        </>
      </ResetCss>
      <Margin mt={45} />
      <DashboardContainer>
        <Section>
          <Outlet />
        </Section>{" "}
        <Margin mt={90} />
      </DashboardContainer>
    </>
  );
};

export default DashboardStructure;
